@import "src/assets/styles/globalStyles";

.top-bar-fragment {
  background: $white;
  box-shadow: 0px 2px 4px #00000014;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  .top-bar-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    @media (min-width: $mobile) {
      height: 64px;
      padding: 21px;
    }
    @media (min-width: $tablet) {
      height: 72px;
      padding: 25px 32px;
    }
    @media (min-width: $desktop) {
      height: 76px;
      padding: 18px 80px;
    }
  }

  .desktop {
    @media (min-width: $mobile) {
      display: none;
    }
    @media (min-width: $desktop) {
      display: block;
    }
  }

  .mobile{
    @media (min-width: $mobile) {
      display: block;
    }
    @media (min-width: $desktop) {
      display: none;
    }
  }

  .links-wrapper {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      margin-right: 46px;
    }

    .passive-link {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $linkColor;
    }

    .passive-link:hover {
      color: $orange;
    }

    .active-link {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $orange;
    }
  }


}
