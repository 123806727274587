@import "src/assets/styles/globalStyles";


.term-and-condition-page{
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
  .title-wrapper{

  }
  @media (min-width: $mobile) {
    padding: 56px 16px 71px 16px;
  }
  @media (min-width: $tablet) {
    padding: 71px 32px 66px 32px;
  }
  @media (min-width: $desktop) {
    padding: 88px 0 120px 0;
  }

  .title{
    font-weight: 800;
    color: $text;
    @media (min-width: $mobile) {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 24px;
    }
    @media (min-width: $tablet) {
      font-size: 40px;
      line-height: 49px;
      margin-bottom: 32px;
    }
    @media (min-width: $desktop) {
      font-size: 40px;
      line-height: 49px;
      margin-bottom: 40px;
    }
  }

  .term-and-condition-wrapper{
    background: $lightGray;
    border-radius: 4px;

    @media (min-width: $mobile) {
      padding: 16px;
    }
    @media (min-width: $tablet) {
      padding: 32px;
    }
    @media (min-width: $desktop) {
      padding: 40px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $text;
    }
  }
}