@import "src/assets/styles/globalStyles";

.home-page {
  .home-background {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media (min-width: $mobile) {
      height: 290px;
    }
    @media (min-width: $tablet) {
      height: 459px;
    }
    @media (min-width: $desktop) {
      height: 682px;
    }

  }

  .home-page-wrapper {

    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    @media (min-width: $mobile) {
      padding: 44px 16px 0 16px;
    }
    @media (min-width: $tablet) {
      padding: 120px 32px 0 32px;
    }
    @media (min-width: $desktop) {
      padding: 207px 80px 0 80px;
    }
  }

  .home-information {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .contact-wrapper {
    display: flex;
    align-items: center;
    border-right: 1px solid $white;
    @media (min-width: $mobile) {
      margin-right: 10px;
    }
    @media (min-width: $tablet) {
      margin-right: 16px;
    }
    @media (min-width: $desktop) {
      margin-right: 20px;
    }
  }

  .information-wrapper {
    margin-top: 123px;
    height: 128px;
    width: auto;
    background: $darkGray;
    border-radius: 16px;
    display: flex;
    align-items: center;

    @media (min-width: $mobile) {
      margin-top: 75px;
      height: 60px;
      width: 330px;
      padding: 0 16px;
      img {
        width: 20px;
        height: 20px;
      }
    }

    @media (min-width: $tablet) {
      margin-top: 100px;
      height: 88px;
      width: 668px;
      padding: 0 24px;
      img {
        width: 40px;
        height: 40px;
      }
    }
    @media (min-width: $desktop) {
      margin-top: 155px;
      width: auto;
      padding: 0 40px;
      img {
        width: 48px;
        height: 48px;
      }
    }

    .time-wrapper {
      display: flex;
      align-items: center;

      .info-style {
        margin-right: 0 !important;
      }

      @media (min-width: $mobile) {
        display: none;
      }
      @media (min-width: $tablet) {
        display: flex;
      }
    }

    .info-style {
      color: $white;
      text-decoration: none;
      font-weight: 600;
      margin-right: 20px;
      margin-left: 8px;

      @media (min-width: $mobile) {
        font-size: 12px;
        line-height: 20px;
        margin-right: 10px;
      }
      @media (min-width: $tablet) {
        font-size: 14px;
        line-height: 20px;
        margin-right: 16px;
      }
      @media (min-width: $desktop) {
        font-size: 24px;
        line-height: 29px;
        margin-right: 20px;
      }
    }
  }

  .home-page-main {
    background: $lightGray;

    @media (min-width: $mobile) {
      padding: 116px 0 105px 0;
    }
    @media (min-width: $tablet) {
      padding: 140px 0 128px 0;
    }
    @media (min-width: $desktop) {
      padding: 191px 0 146px 0;
    }
  }

  .home-page-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: $mobile) {
      padding: 0 36px;
    }
    @media (min-width: $tablet) {
      padding: 0 32px;
    }
    @media (min-width: $desktop) {
      padding: 0;
    }
  }


  .items:hover {
    -webkit-animation: colorUp 0.5s forwards;
    animation: colorUp 0.5s forwards;

    img {
      filter: brightness(0) invert(1);
    }

    p {
      color: white;
    }

    button {
      color: $darkGray;
    }
  }

  .align-start-column {
    display: flex;
    align-items: flex-start;
    @media (max-width: $desktop) {
      flex-direction: column;
    }
  }

  .items {
    background: $white;
    border: 1px solid $white;
    border-radius: 8px;
    width: 302px;
    padding: 24px;
    height: auto;
    min-height: 280px;
    margin-top: 54px;
    margin-right: 24px;

    @media (max-width: $desktop) {
      width: 100%;
      min-height: 266px;
      margin-top: 20px;
      margin-right: 0;
    }

    .item-title {
      margin-top: 20px;
      font-size: 17px;
      line-height: 24px;
      font-weight: bold;
      color: $text;

    }

    .item-sub-title {
      margin-top: 16px;
      color: $lightText;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      button{
        margin-left: 8px;
      }
    }
  }

  .items:last-child {
    //margin-right: 0px;
  }


  .service-height {
    @media (min-width: $mobile) {
      height: 1280px;
    }
    @media (min-width: $tablet) {
      height: 976px;
    }
    @media (min-width: $desktop) {
      height: 595px;
    }

  }

  .service-wrapper {
    background: #37474F;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $mobile) {
      padding: 36px 16px;
      height: 413px;
    }
    @media (min-width: $tablet) {
      padding: 48px 24px;
      height: 380px;
    }
    @media (min-width: $desktop) {
      padding: 60px 0px;
      height: 415px;
    }

    .service-title {
      font-weight: 800;
      color: $white;
      @media (min-width: $mobile) {
        padding: 0;
        font-size: 28px;
        line-height: 42px;
      }
      @media (min-width: $tablet) {
        padding: 0;
        font-size: 40px;
        line-height: 50px;
      }
    }

    .service-subtitle {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $white;
      text-align: center;

      @media (min-width: $mobile) {
        width: 80%;
        margin: 16px 0 40px 0;
      }
      @media (min-width: $tablet) {
        width: 543px;
        margin: 20px 0 54px 0;
      }
      @media (min-width: $desktop) {
        width: 502px;
        margin: 20px 0 48px 0;
      }
    }

    .line {
      -webkit-animation: slideUp 0.5s forwards;
      animation: slideUp 0.5s forwards;
    }


    @-webkit-keyframes colorUp {
      from {
        background: $lightGray;
      }

      to {
        background: $orange;
      }
    }

    @keyframes colorUp {
      from {
        background: $lightGray;
      }

      to {
        background: $orange;
      }
    }

    .service-card:hover {
      -webkit-animation: colorUp 0.5s forwards;
      animation: colorUp 0.5s forwards;
    }

    .service-card:hover {
      img {
        filter: brightness(0) invert(1);
      }

      p {
        color: white;
      }

      .links {
        color: white !important;
      }

      @-webkit-keyframes slideWidth {
        from {
          width: 84px;
        }
        to {
          width: 100%;
        }
      }
      @keyframes slideWidth {
        from {
          width: 84px;
        }
        to {
          width: 100%;
        }
      }

      .line {
        -webkit-animation: slideWidth 0.5s forwards;
        animation: slideWidth 0.5s forwards;
        color: white;
        border: 1px solid white;
      }

      .circle {
        background: white !important;
      }
    }

    .service-card {
      background: $lightGray;
      border-radius: 16px;

      @media (min-width: $mobile) {
        width: 342px;
        padding: 28px;
        height: 357px;
        margin-bottom: 16px;
      }
      @media (min-width: $tablet) {
        width: 705px;
        padding: 32px;
        height: 244px;
        margin-bottom: 28px;
      }
      @media (min-width: $desktop) {
        width: 405px;
        padding: 16px 32px ;
        height: 385px;
        margin-right: 32px;
        margin-bottom: 0;
      }

      .service-card-title-image {
        @media (min-width: $mobile) {
          display: flex;
          flex-direction: column;
          img {
            width: 74px;
            height: 74px;
          }
        }
        @media (min-width: $tablet) {
          display: flex;
          flex-direction: row;
          img {
            width: 80px;
            height: 80px;
          }
        }
        @media (min-width: $desktop) {
          display: flex;
          flex-direction: column;
          img {
            width: 80px;
            height: 80px;
          }
        }
      }

      .mobile-desktop{
        @media (min-width: $mobile) {
          display: block;
        }
        @media (min-width: $tablet) {
          display: none;
        }
        @media (min-width: $desktop) {
          display: block;
        }
      }

      .image-title{
        display: flex;
        @media (min-width: $mobile) {
          margin-bottom: 16px;
          align-items: center;
        }
        @media (min-width: $tablet) {
          margin-bottom: 0;
          align-items: flex-start;
        }
        @media (min-width: $desktop) {
          margin-bottom: 28px;
          align-items: center;
        }
      }

      .service-card-info {
        @media (min-width: $tablet) {
          margin-left: 16px;
        }
        @media (min-width: $desktop) {
          margin-left: 0px;
        }
      }

      .line {
        border: 1px solid $orange;
        background: transparent;
        width: 84px;
        align-items: flex-start;
      }

      .title {
        font-size: 24px;
        line-height: 36px;
        font-weight: 800;
        color: $text;
        //margin: 28px 0;

        @media (min-width: $mobile) {
          //margin: 16px 0;
          font-size: 19px;
          line-height: 29px;
          margin: 0 0 0 8px;
        }
        @media (min-width: $tablet) {
          font-size: 23px;
          line-height: 29px;
          margin: 0 0 24px 0;
        }
        @media (min-width: $desktop) {
          line-height: 36px;
          height: 63px;
          margin: 0 0 0 8px;
        }
      }

      .links-wrapper {
        overflow: auto;

        @media (min-width: $mobile) {
          margin-top: 16px;
          height: 215px;
        }
        @media (min-width: $tablet) {
          margin-top: 24px;
          height: 120px;
        }
        @media (min-width: $desktop) {
          margin-top: 28px;
          height: 222px;
        }

        .link-and-circle {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }

        .link-and-circle:last-child {
          margin-bottom: 0;
        }

        .circle {
          width: 6px;
          height: 6px;
          background: $text;
          border-radius: 40px;
          margin-right: 8px;
        }

        .links {
          font-size: 16px;
          line-height: 24px;
          color: $lightText;
          list-style: disc;
        }
      }
    }

    .service-card:last-child {
      //margin-right: 0px;
    }
  }

  .why-choose-us {
    position: relative;
    max-width: 1440px;
    @media (min-width: $mobile) {
      margin: 112px auto 0 auto;
      padding: 0;
      height: 642px;
    }
    @media (min-width: $tablet) {
      padding: 0 32px;
      margin: 140px auto 0 auto;
      height: 932px;
    }
    @media (min-width: $desktop) {
      padding: 0 80px;
      margin: 140px auto 0 auto;
      height: 642px;
    }

    .electric-image {
      z-index: 1;
      width: 575px;
      height: 554px;
      position: absolute;
      @media (min-width: $mobile) {
        width: 308px;
        height: 296px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
      }
      @media (min-width: $tablet) {
        width: 431px;
        height: 415px;
        margin-left: 32px;
      }
      @media (min-width: $desktop) {
        width: 575px;
        height: 554px;
        margin-left: 80px;
      }
    }

    .why-info-wrapper {
      width: auto;
      max-width: 1012px;
      height: 579px;
      background: $lightGray;

      margin-left: 270px;
      position: relative;
      z-index: 0;
      border-radius: 24px;
      @media (min-width: $mobile) {
        margin-left: 0px;
        max-height: 530px;
        height: auto;
        top: 170px;
        padding: 0 20px;
      }
      @media (min-width: $tablet) {
        margin-left: 0;
        top: 280px;
        height: 637px;
        max-height: 637px;
      }
      @media (min-width: $desktop) {
        margin-left: 270px;
        top: 63px;
        height: 579px;
        max-height: 579px;
      }
    }

    .title-and-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: $mobile) {
        margin-left: 0;
        padding: 165px 0 32px;
      }
      @media (min-width: $tablet) {
        margin: 0 auto;
        padding: 180px 0 60px;
      }
      @media (min-width: $desktop) {
        margin-left: 230px;
        padding: 90px 0;
      }

      .title {
        font-weight: 800;
        color: $text;
        text-transform: capitalize;

        @media (min-width: $mobile) {
          font-size: 28px;
          line-height: 42px;
          margin-bottom: 32px;
        }
        @media (min-width: $tablet) {
          font-size: 40px;
          line-height: 50px;
          margin-bottom: 48px;
        }
        @media (min-width: $desktop) {
          font-size: 40px;
          line-height: 50px;
          margin-bottom: 50px;
        }
      }
    }

    .why-cards {
      display: flex;
      align-items: center;
      @media (min-width: $mobile) {
        margin-bottom: 14px;
      }
      @media (min-width: $tablet) {
        margin-bottom: 33px;
      }
      @media (min-width: $desktop) {
        margin-bottom: 24px;
      }

      p {
        font-weight: 500;
        color: $text;
        @media (min-width: $mobile) {
          margin-left: 12px;
          font-size: 18px;
          line-height: 24px;
        }
        @media (min-width: $tablet) {
          margin-left: 19px;
          font-size: 24px;
          line-height: 24px;
        }
        @media (min-width: $desktop) {
          margin-left: 16px;
        }
      }

      img {
        @media (min-width: $mobile) {
          width: 20px;
          height: 16px;
        }
        @media (min-width: $tablet) {
          width: 29px;
          height: 23px;
        }
        @media (min-width: $desktop) {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  .trust-us {
    @media (min-width: $mobile) {
      margin-top: 85px;
      padding: 0 0 0 16px;
    }
    @media (min-width: $tablet) {
      margin-top: 96px;
      padding: 0 0 0 32px;
    }
    @media (min-width: $desktop) {
      margin-top: 128px;
      padding: 0;
    }

    .trust-us-subtitle {
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 16px 0 85px 0;

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $lightText;

        @media (min-width: $mobile) {
          width: 302px;
        }
        @media (min-width: $tablet) {
          width: 600px;
        }
        @media (min-width: $desktop) {
          width: 600px;
        }
      }
    }
  }

  .trust-us-cards {
    display: flex;
    justify-content: center;
    //margin-top: px;
    max-width: 954px;
    margin: 50px auto;

  }

  .trust-cards {
    display: flex;
    justify-content: center;
    width: 302px;
    height: 214px;
    background: $lightGray;
    padding: 0 24px;
    border-radius: 4px;
    margin-top: 40px;
    position: relative;
    margin-right: 24px;

    .trust-cards-user {
      position: absolute;
      width: 71px;
      height: 71px;
      border-radius: 40px;
      margin-top: -40px;
    }

    .trust-cards-info {
      text-align: center;
      margin-top: 45px;
    }

    .name {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      color: $text;
    }

    .position {
      margin: 8px 16px;
      font-size: 14px;
      line-height: 17px;
      color: $textDark;
    }

    .info {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $lightText;
    }
  }


  .partners {
    max-width: 1440px;
    @media (min-width: $mobile) {
      margin: 73px auto 0 auto;
      padding: 0 0 0 16px;
    }
    @media (min-width: $tablet) {
      margin: 96px auto 0 auto;
      padding: 0 0 0 32px;
    }
    @media (min-width: $desktop) {
      margin: 128px auto 0 auto;
      padding: 0;
    }

    .partners-subtitle {
      text-align: center;
      display: flex;
      justify-content: center;
      @media (min-width: $mobile) {
        margin: 16px 0 38px 0;
      }
      @media (min-width: $tablet) {
        margin: 16px 0 15px 0;
      }
      @media (min-width: $desktop) {
        margin: 16px 0 36px 0;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $darkGray;
        @media (min-width: $mobile) {
          width: 308px;
        }
        @media (min-width: $tablet) {
          width: 598px;
        }
        @media (min-width: $desktop) {
          width: 602px;
        }
      }
    }
  }

  .partner-logo{
    width: 170px;
    object-fit: contain;
    height: 144px;
  }
}