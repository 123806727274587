@import "src/assets/styles/globalStyles";

.contact-us-fragment {
  max-width: 1440px;
  @media (min-width: $mobile) {
    margin: 109px auto 0 auto;
    padding: 0;
  }
  @media (min-width: $tablet) {
    margin: 96px auto 0 auto;
    padding: 0 71px;
  }
  @media (min-width: $desktop) {
    margin: 128px auto 0 auto;
    padding: 0 80px;
  }

  .contact-us-wrapper {
    margin: 0 auto;
    background: $lightGray;
    border-radius: 24px;
    @media (min-width: $mobile) {
      padding: 40px 18px;
      width: 100%;
    }
    @media (min-width: $tablet) {
      padding: 80px;
      width: 625px;
    }
    @media (min-width: $desktop) {
      padding: 90px;
      width: 100%;
    }

    .talk-section {
      @media (min-width: $mobile) {
        max-width: 342px;
        width: auto;
      }
      @media (min-width: $tablet) {
        max-width: 465px;
        width: 465px;
      }
    }

    .contact-us-section {
      height: auto;
      background: $darkGray;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: $mobile) {
        margin-top: 35px;
        padding: 32px 32px 10px 32px;
        max-width: 342px;
        width: auto;
      }
      @media (min-width: $tablet) {
        padding: 48px 48px 10px 48px;
        max-width: 465px;
        width: auto;
      }
      @media (min-width: $desktop) {
        margin-top: 0px;
      }

      .title {
        @media (min-width: $mobile) {
          font-size: 28px;
          line-height: 50px;
        }
        @media (min-width: $tablet) {
          font-size: 40px;
          line-height: 50px;
        }

        font-weight: 800;
        color: $white;
        margin-bottom: 36px;
      }

      .links {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $white;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        text-decoration: none;

        img {
          margin-right: 16px;
          filter: brightness(0) invert(1);
        }
      }


    }

    .form-wrapper {
      //margin-bottom: 50px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .MuiTextField-root {
        margin-top: 20px;
      }
    }
  }
}