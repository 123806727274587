@import "src/assets/styles/globalStyles";

.contact-page {

  .contact-background {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media (min-width: $mobile) {
      height: 350px;
    }
    @media (min-width: $tablet) {
      height: 387px;
    }
    @media (min-width: $desktop) {
      height: 523px;
    }
  }

  .contact-page-wrapper {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    @media (min-width: $mobile) {
      padding: 72px 16px 0 16px;
    }
    @media (min-width: $tablet) {
      padding: 100px 32px 0 32px;
    }
    @media (min-width: $desktop) {
      padding: 167px 80px 0 80px;
    }
  }

  .contact-subtitle {
    color: $white;
    line-height: 20px;
    font-size: 16px;
    @media (min-width: $mobile) {
      width: 329px;
      margin-top: 16px;
    }
    @media (min-width: $tablet) {
      width: 409px;
      margin-top: 24px;
    }
    @media (min-width: $desktop) {
      width: 668px;
    }
  }


  .connect-map {
    position: relative;
    max-width: 1280px;
    width: 100%;
    height: 500px;

    @media (min-width: $mobile) {
      height: 277px;
      margin: 72px auto 96px auto;
    }
    @media (min-width: $tablet) {
      height: 475px;
      margin: 96px auto 96px auto;
    }
    @media (min-width: $desktop) {
      height: 500px;
      margin: 0 auto;
    }

    .marker-pin {
      width: 28px;
      height: 38px;
      & img {
        width: 100%;
      }
      @media (min-width: $mobile) {
      width: 23px;
      height: 33px;
    }
    }


    .gmnoprint {
      margin-right: 32px !important;
      margin-bottom: 10px !important;
      @media (max-width: 767px) {
        margin-right: 24px !important;
        margin-bottom: 0 !important;
      }
      & > div > div:not(:first-child),
      &.gm-style-cc {
        display: none !important;
      }
    }
    .gm-control-active {
      background: $orange !important;
      color: white !important;
      width: 30px !important;
      height: 30px !important;
      left: 12px !important;
      margin-bottom: 16px !important;
      line-height: 20px !important;
      border-radius: 4px;
      img{
        display: none !important;
      }
      @media (max-width: 767px) {
        margin-bottom: 8px !important;

      }
    }
    .gm-control-active:first-child:before {
      content: "+" !important;
      color: white !important;
      font-size: 36px !important;
      font-weight: bold !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      margin-top: -5px!important;
    }
    .gm-control-active:nth-of-type(2):before {
      content: "−" !important;
      color: white !important;
      font-size: 36px !important;
      font-weight: bold !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      margin-top: -5px!important;
    }
    .gmnoprint {
      div {
        background: none !important;
        box-shadow: none !important;
      }
    }

  }


  .form-fragment{
    margin: 0 auto;

    @media (min-width: $mobile) {
      padding: 0 16px 72px 16px;
      margin-top: 72px;
    }
    @media (min-width: $tablet) {
      padding: 0 0 96px 0;
      margin-top: 96px;
      max-width: 704px;
    }
    @media (min-width: $desktop) {
      padding: 0 0 128px 0;
      margin-top: 128px;
      max-width: 954px;
    }

    .form-subtitle{
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $lightText;
      @media (min-width: $mobile) {
        margin: 16px 0 32px 0;
        width: 343px;
      }
      @media (min-width: $tablet) {
        margin: 16px 0 56px 0;
        width: 704px;
      }
      @media (min-width: $desktop) {
        width: 465px;
        margin: 16px 0 32px 0;
      }
    }

    .input-wrapper{
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media (min-width: $mobile) {
       flex-direction: column;
      }
      @media (min-width: $tablet) {
        flex-direction: row;
      }

      .input-right{
        @media (min-width: $mobile) {
          margin-right: 0;
        }
        @media (min-width: $tablet) {
          margin-right: 24px;
        }
      }

      p{
        font-weight: bold;
        line-height: 20px;
        font-size: 16px;
        margin-bottom: 8px;
        color: $lightText;
        @media (min-width: $mobile) {
          margin-top: 18px;
        }
        @media (min-width: $tablet) {
          margin-top: 24px;
        }
      }

      input{
        background: $white;
        border: 1px solid #B6C1CB;
        border-radius: 4px;
        height: 48px;
        padding: 0 12px;

        @media (min-width: $mobile) {
          width: 100%;
        }
        @media (min-width: $tablet) {
          width: 340px;
        }
        @media (min-width: $desktop) {
          width: 465px;
        }
      }

      textarea{
        width: 953px;
        height: 96px;
        background: $white;
        border: 1px solid #B6C1CB;
        border-radius: 4px;
        resize: none;
        padding: 14px 16px;
        //font-size: 16px;
        line-height: 24px;
        font-weight: 500;

        @media (min-width: $mobile) {
          width: 100%;
        }
        @media (min-width: $tablet) {
          width: 704px;
        }
        @media (min-width: $desktop) {
          width: 953px;
        }

        ::placeholder{
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #B6C0CB;
        }
      }

      .select-wrapper{
        background: $white;
        border: 1px solid #B6C1CB;
        border-radius: 4px;
        height: 48px;
        @media (min-width: $mobile) {
          width: 100%;
        }
        @media (min-width: $tablet) {
          width: 704px;
        }
        @media (min-width: $desktop) {
          width: 953px;
        }
      }

      select{
        height: 46px;
        border: none;
        width: 99%;
        padding: 0 12px;
        border-radius: 4px;
      }
    }
  }

}