@import "src/assets/styles/globalStyles";

.projects-page {

  .projects-background {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media (min-width: $mobile) {
      height: 350px;
    }
    @media (min-width: $tablet) {
      height: 387px;
    }
    @media (min-width: $desktop) {
      height: 523px;
    }
  }

  .projects-page-wrapper {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    @media (min-width: $mobile) {
      padding: 173px 16px 0 16px;
    }
    @media (min-width: $tablet) {
      padding: 120px 32px 0 32px;
    }
    @media (min-width: $desktop) {
      padding: 183px 80px 0 80px;
    }
  }

  .projects-subtitle {
    color: $white;
    line-height: 20px;
    font-size: 16px;
    @media (min-width: $mobile) {
      width: 329px;
      margin-top: 16px;
    }
    @media (min-width: $tablet) {
      width: 409px;
      margin-top: 24px;
    }
    @media (min-width: $desktop) {
      width: 668px;
    }
  }


  .project-main {
    background: $lightGray;

    @media (min-width: $mobile) {
      margin-top: 72px;
    }
    @media (min-width: $tablet) {
      margin-top: 96px;
    }
    @media (min-width: $desktop) {
      margin-top: 256px;
    }

    .project-main-wrapper {
      max-width: 1440px;
      margin: 0 auto;

      @media (min-width: $mobile) {
        padding: 28px 0 28px 16px;
      }
      @media (min-width: $tablet) {
        padding: 32px;
      }
      @media (min-width: $desktop) {
        padding: 50px 80px;
      }
    }

    .project-item {
      display: flex;
      @media (min-width: $mobile) {
        flex-direction: column;
      }
      @media (min-width: $desktop) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .projects-title {
      font-weight: 800;
      color: $text;
      font-size: 40px;
      line-height: 49px;
    }

    .projects-subtitle {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: $text;
      margin: 32px 0 16px 0;
    }

    .projects-info {
      width: 436px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: $text;
      @media (min-width: $mobile) {
        width: 320px;
      }
      @media (min-width: $tablet) {
        width: 449px;
      }
      @media (min-width: $desktop) {
        width: 436px;
      }
    }

    .projects-urder-list {
      list-style: disc;
      margin-top: 16px;
      margin-left: 18px;

      li {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $text;
      }
    }

    .projects-image {
      border-radius: 16px;
      object-fit: cover;

      @media (min-width: $mobile) {
        width: 320px;
        height: 260px;
        margin-top: 29px;
      }
      @media (min-width: $tablet) {
        width: 590px;
        height: 484px;
        margin-top: 32px;
      }
      @media (min-width: $desktop) {
        margin-top: -177px;
        width: 590px;
        height: 487px;
      }
    }

    .selectors-wrapper {
      width: 557px;
      padding: 8px;
      background: $white;
      border-radius: 4px;
      display: flex;
      align-items: center;
      @media (min-width: $mobile) {
        margin: 64px auto 24px auto;
      }
      @media (min-width: $tablet) {
        margin: 80px auto 28px auto;
      }
      @media (min-width: $desktop) {
        margin: 142px auto 24px auto;
      }

      button {
        width: 100%;
        height: 52px;
        background: $lightGray;
        border-radius: 4px;
        border: none;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: $text;
        img{
          width: 28px;
          height: 28px;
          margin-right: 12px;
        }
      }
      button:last-child{
        margin-right: 0;
      }

      button:hover {
        background: $orange;
        color:$white;

        img{
          filter: brightness(0) invert(1);
        }
      }
    }

    .selectors-subtitle{
      width: 609px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $text;

      @media (min-width: $mobile) {
        margin: 0;
        width: 320px;
        text-align: start;
      }
      @media (min-width: $tablet) {
        width: 557px;
      }
      @media (min-width: $desktop) {
        margin: 0 auto;
        width: 609px;
        text-align: center;
      }
    }

    .main-carousel{
      margin-top: 32px;
    }

    .slide-image{
      border-radius: 16px;
      margin-right: 16px;
      object-fit: cover;
      @media (min-width: $mobile) {
        width: 320px;
        height: 260px;
      }
      @media (min-width: $tablet) {
        width: 416px;
        height: 322px;
      }
    }

  }
}