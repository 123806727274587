$white: #FFFFFF;
$darkWhite: #F7F9FC;
$orange: #FE571B;
$darkOrange: #DD3C14;
$lightGray: #F8FAFD;
$darkGray: #37474F;
$text: #0E2A47;
$textDark: #0B22398B;
$lightText: #6D8297;
$linkColor: #79848A;
$hrColor: #6D82974D;

$mobile: 320px;
$tablet: 767px;
$desktop: 1279px;

body {
  font-family: "Proxima Nova", sans-serif;

  @media (min-width: $mobile) {
    padding-top: 64px;
  }
  @media (min-width: $tablet) {
    padding-top: 72px;
  }
  @media (min-width: $desktop) {
    padding-top: 76px;
  }
}

::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
  color: #545F7E
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


// Global styles

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (min-width: $mobile) {
    flex-direction: column-reverse;
  }
  @media (min-width: $tablet) {
    flex-direction: column-reverse;
  }
  @media (min-width: $desktop) {
    flex-direction: row;
  }
}


.flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-align-start {
  display: flex;
  align-items: flex-start;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-end {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.page-title {
  font-size: 60px;
  line-height: 90px;
  font-weight: 800;
  letter-spacing: 0;
  color: $white;

  @media (min-width: $mobile) {
    font-size: 28px;
    line-height: 42px;
  }
  @media (min-width: $tablet) {
    font-size: 40px;
    line-height: 50px;
  }
  @media (min-width: $desktop) {
    font-size: 60px;
    line-height: 90px;
  }
}

.page-subtitle {
  color: $white;

  @media (min-width: $mobile) {
    font-size: 16px;
    width: 275px;
    line-height: 24px;
    margin-top: 16px;
  }
  @media (min-width: $tablet) {
    font-size: 24px;
    line-height: 36px;
    width: 459px;
    margin-top: 24px;
  }
  @media (min-width: $desktop) {
    width: 550px;

  }
}

.orange-text {
  color: $orange;
}

.main-title {
  font-weight: 800;
  color: $text;
  text-align: center;
  @media (min-width: $mobile) {
    font-size: 28px;
    line-height: 42px;
  }
  @media (min-width: $tablet) {
    font-size: 40px;
    line-height: 60px;
  }
}

.main-title-start {
  font-weight: 800;
  font-size: 40px;
  line-height: 60px;
  color: $text;
}

.main-subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $lightText;
}

@mixin content {
  padding: 0 80px;
  max-width: 1440px;
  margin: 0 auto;
}

.wrapper-max {
  padding: 0 80px;
  max-width: 1440px;
  margin: 0 auto;
  @media (min-width: $mobile) {
    padding: 0 35px;
  }
  @media (min-width: $tablet) {
    padding: 0 32px;
  }
  @media (min-width: $desktop) {
    padding: 0 80px;
  }
}

// Global styles End

.animation {
  -webkit-animation: slideUp 1s forwards;
  animation: slideUp 1s forwards;
}

@-webkit-keyframes slideUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;

  }
}

.instead-of-carousel-section {
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
}


.anchor-links {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
    font-size: 32px;
    font-weight: 800;
    line-height: 39px;
    color: $text;
    margin-bottom: 72px;
  }
}

.close-button {
  display: flex;
  justify-content: flex-end;
  padding: 24px 32px;
  margin-bottom: 60px;
}

.anchor-fragment {
  width: 350px;
}

.logo-style{
  @media (min-width: $mobile) {
    width: 72px;
    height: 22px;
  }
  @media (min-width: $tablet) {
    width: 94px;
    height: 28px;
  }
  @media (min-width: $desktop) {
    width: 130px;
    height: 40px;
  }

}

.contact-us-bottom-padding{
  @media (min-width: $mobile) {
    padding-bottom: 72px;
  }
  @media (min-width: $tablet) {
    padding-bottom: 96px;
  }
  @media (min-width: $desktop) {
    padding-bottom: 128px;
  }
}


.split-animation {
  & .split {
    overflow: hidden;
    & > .split-item {
      transform: translateY(100%);
      will-change: transform;
      &.slide-up {
        transform: translateZ(0);
        transition: transform 1s cubic-bezier(0.4, 0, 0, 1);
        will-change: transform;
      }
      &.split-2 {
        transition-delay: 0.2s !important;
      }
      &.split-3 {
        transition-delay: 0.4s !important;
      }
      &.split-4 {
        transition-delay: 0.6s !important;
      }
    }
  }
}

.only-mobile{
  @media (min-width: $mobile) {
   display: block;
  }
  @media (min-width: $tablet) {
   display: none;
  }
}

.only-tablet{
  @media (min-width: $mobile) {
    display: none;
  }
  @media (min-width: $tablet) {
    display: block;
  }
  @media (min-width: $desktop) {
   display: none;
  }
}

.only-desktop{
  @media (min-width: $mobile) {
    display: none;
  }
  @media (min-width: $tablet) {
    display: none;
  }
  @media (min-width: $desktop) {
    display: block;
  }
}