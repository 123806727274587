@import "src/assets/styles/globalStyles";

.main-button{
    background: $orange;
    padding: 0 16px;
    height: 40px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    line-height: 20px;
    color: $white;
}

.main-button:hover{
  background: $darkOrange;
}

.read-more-button{
    border: none;
    background: transparent;
    width: auto;
    padding: 0;
    color: $orange;
    font-size: 16px;
    font-weight: 500;

}