@import "src/assets/styles/globalStyles";

.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $text;
  height: 100vh;

  @media (min-width: $mobile) {
    align-items: flex-start;
    padding-top: 30px;
    img{
      width: 144px;
      height: 164px;
    }

  }
  @media (min-width: $tablet) {
    align-items: center;
    img{
      width: 263px;
      height: 299px;
    }
  }
  @media (min-width: $desktop) {
    align-items: center;
    img{
      width: 337px;
      height: 383px;
    }
  }
  .info-wrapper {
    display: flex;
    align-items: center;
    @media (min-width: $mobile) {
      flex-direction: column;

    }
    @media (min-width: $tablet) {
      flex-direction: row;
    }
  }

  .not-found-texts{
    @media (min-width: $mobile) {
      text-align: center;
    }
    @media (min-width: $tablet) {
      text-align: start;
    }
    .title{
      font-weight: 800;
      color: $white;
      @media (min-width: $mobile) {
        font-size: 24px;
        line-height: 29px;
      }
      @media (min-width: $tablet) {
        font-size: 40px;
        line-height: 49px;
      }
    }
    .sub{
      font-weight: 800;
      color: $white;
      @media (min-width: $mobile) {
        font-size: 18px;
        line-height: 22px;
        margin: 16px 0 8px 0;
      }
      @media (min-width: $tablet) {
        font-size: 24px;
        line-height: 29px;
        margin: 24px 0 8px 0;
      }
    }
    .info{
      font-weight: 600;
      margin-bottom: 16px;
      @media (min-width: $mobile) {
        font-size: 18px;
        line-height: 22px;
        color: $lightText;
      }
      @media (min-width: $tablet) {
        font-size: 16px;
        line-height: 20px;
        color: $white;
      }
    }
  }
}