@import "src/assets/styles/globalStyles";

.footer-fragment {
  background: $lightGray;
  width: 100%;
  padding: 28px 0;

  .footer-fragment-max {
    @include content
  }

  .footer-logo {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .links-wrapper {
    display: flex;
    justify-content: center;
    margin: 24px 0 26px 0;

    a {
      text-decoration: none;
      @media (min-width: $mobile) {
        margin-right: 45px;
      }
      @media (min-width: $tablet) {
        margin-right: 56px;
      }
    }

    a:last-child {
      margin-right: 0;
    }

    .active-link {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
      color: $darkGray;
    }

    .passive-link {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: $lightText;
    }
  }

  .hr {
    border: 1px solid $hrColor;
  }

  .copyright {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    @media (min-width: $mobile) {
      flex-direction: column;
    }
    @media (min-width: $tablet) {
      flex-direction: row;
    }

    a {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      color: $lightText;
      text-decoration: none;
    }

    p {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      color: $lightText;
      @media (min-width: $mobile) {
        margin-bottom: 8px;
      }
      @media (min-width: $tablet) {
        margin-bottom: 0;
      }

    }
  }

}
